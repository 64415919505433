import React from 'react';
import styled from 'styled-components';

import { responsiveFont } from 'styles/utils';
import { variables, breakpoints } from 'styles/variables';
import { Container } from 'components/container/Container';
import { Column } from 'components/grid/Column';
import { Row } from 'components/grid/Row';
import { graphql } from 'gatsby';

import { SimpleFooter } from 'components/footer/SimpleFooter';

import AniLink from 'gatsby-plugin-transition-link/AniLink';

import Back from 'assets/svg/arrow-left.svg';

import Helmet from 'react-helmet';

import { PopupText } from 'react-calendly';

import addToMailchimp from 'gatsby-plugin-mailchimp';

import {useTranslation} from 'gatsby-plugin-react-i18next';


// tslint:disable no-default-export

const ContactRoot = styled.div`
  background: ${variables.colors.background};
  min-height: 100vh;
`;

const Wrapper = styled.div`
  padding: 0px 0;

  @media (min-width: ${breakpoints.md}) {
    padding: 0px 0;
  }
`;

const SectionTitle = styled.p`
  ${responsiveFont(38, 44)};

  font-family: ${variables.font.familyHeading};
  font-weight: 900;
  padding-bottom: 20px;
`;

const SubText = styled.p`
  ${responsiveFont(14, 18)};
  font-family: ${variables.font.familyHeading};
  font-weight: 400;
  padding-top: 20px;
`;

const Links = styled.div`
  ${responsiveFont(14, 18)};
  font-family: ${variables.font.familyHeading};
  font-weight: 400;
  padding-top: 40px;
`;

const LargeInput = styled.input`
  border-bottom: 1px #000000 solid;
  width: 100%;
  ${responsiveFont(24, 38)};
  border-radius: 0px;

  font-family: ${variables.font.familyHeading};
  font-weight: 300;
  outline: none;
  margin-bottom: 20px;
`;

const LargeLabel = styled.label`
  width: 100%;
  ${responsiveFont(22, 28)};

  font-family: ${variables.font.familyHeading};
  font-weight: 900;
`;

const BackIcon = styled(Back)`
  margin-top: 20px;
  transition: 200ms opacity ease-in-out;
  &:hover {
    opacity: 0.5;
  }
`;

const SubmitButton = styled.div`
  margin-top: 20px;
  transition: 200ms opacity ease-in-out;
  &:hover {
    opacity: 0.7;
  }
`;

const ExternalLink = styled.a`
  ${responsiveFont(14, 18)};
  font-family: ${variables.font.familyHeading};
  font-weight: 400;
  padding-top: 20px;
  color: ${variables.colors.font};
  text-decoration: none;
  transition: 200ms opacity ease-in-out;
  &:hover {
    opacity: 0.5;
  }
`;

export default () => {
  var prefillData = {
    email: '',
    name: '',
  };
  const handleChangeName = (e) => {
    prefillData.name = e.target.value;
  };
  const handleChangeEmail = (e) => {
    prefillData.email = e.target.value;
  };

  const mailchimpSignup = () => {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(prefillData.email)
    ) {
      addToMailchimp(prefillData.email, { FNAME: prefillData.name });
    }
  };

  const {t} = useTranslation();

  return (
    <>
      <Helmet title={t('title')} />
      <ContactRoot>
        <Container>
          <Wrapper>
            <Row>
              <Column md={2} lg={2} sm={12}></Column>
              <Column md={8} lg={8} sm={12}>
                <AniLink cover direction="right" bg="#000" to="/">
                  <BackIcon />
                </AniLink>
                <SubText>{t('aboveTitle')}</SubText>
                <SectionTitle>{t('title')}</SectionTitle>
                <form method="post" action="#">
                  <LargeLabel>{t('nameLabel')}</LargeLabel>
                  <LargeInput
                    id="inputName"
                    type="name"
                    name="_name"
                    placeholder={t('nameHolder')}
                    onChange={handleChangeName}
                  />
                  <LargeLabel>{t('emailLabel')}</LargeLabel>
                  <LargeInput
                    id="inputEmail"
                    type="email"
                    name="_email"
                    placeholder={t('emailHolder')}
                    onChange={handleChangeEmail}
                  />
                  <SubmitButton onClick={mailchimpSignup}>
                    <PopupText
                      pageSettings={{
                        backgroundColor: '${variables.colors.background}',
                        hideEventTypeDetails: true,
                        hideLandingPageDetails: false,
                        primaryColor: '${variables.colors.font}',
                        textColor: '${variables.colors.font}',
                      }}
                      prefill={prefillData}
                      styles={{
                        background: '#000000',
                        padding: '10px 20px 10px 20px',
                        fontSize: '18px',
                        textDecoration: 'none',
                        color: '#ffffff',
                        borderRadius: '5px',
                      }}
                      text={t('book')}
                      url="https://calendly.com/trampolinstudio/30min"
                      utm={{
                        utmCampaign: 'LetsMeet',
                        utmContent: 'LetsMeet',
                        utmMedium: 'Web',
                        utmSource: 'Trampolin.studio',
                      }}
                    />
                  </SubmitButton>
                </form>
                <Links>
                  <ExternalLink href="tel:+386 40 377 061">+386 40 377 061</ExternalLink>
                  <br />
                  <ExternalLink href="mailto:hello@trampolin.studio">
                    hello@trampolin.studio
                  </ExternalLink>
                  <br />
                  <br />
                  <br />Trampolin d.o.o.
                  <br />Glavni trg 8
                  <br />2000 Maribor, Slovenia
                  <br />SI69662746


                  <br />

                  <SimpleFooter />
                </Links>
              </Column>
            </Row>
          </Wrapper>
        </Container>
      </ContactRoot>
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "contact", "index", "terms", "work"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
